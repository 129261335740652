<template>
	<section>
		<div class="card">
			<div class="card-header">
				<div class="card-title h4">Crear Orden de Servicio</div>
			</div>
			<div class="card-body">
				<form @submit.prevent="enviar()">
					<div class="columns">
						<div class="form-group column col-sm-12 col-4">
							<label class="form-label">Fecha de visita</label>
							<input class="form-input" type="datetime-local" v-model="form.datos.fecha" required>
						</div>
						<div class="form-group column col-sm-12 col-4">
							<label class="form-label">Cliente</label>
							<select class="form-select" v-model="form.datos.cliente" required>
								<option v-for="(i, k) of clientes" :key="k" :value="i.id">{{ i.nombre }}</option>
							</select>
						</div>
						<div class="form-group column col-sm-12 col-4">
							<label class="form-label">Técnico</label>
							<select class="form-select" v-model="form.datos.tecnico" required>
								<option v-for="(i, k) of tecnicos" :key="k" :value="i.id">{{ i.nombre }}</option>
							</select>
						</div>
					</div>
					<div class="divider"></div>
					<div class="form-group">
						<button type="submit" class="btn btn-primary" :class="form.cargando ? 'loading' : ''">Crear Orden</button>
					</div>
				</form>
			</div>
		</div>
		<div class="card">
			<div class="card-header">
				<div class="card-title h4">Ordenes activas</div>
			</div>
			<div v-if="tabla.cargando" class="card-body">
				<div class="loading loading-lg"></div>
			</div>
			<table v-else class="table table-striped table-hover">
				<thead>
					<tr>
						<th class="text-center">ID Orden</th>
						<th>Fecha</th>
						<th>Cliente</th>
						<th>Técnico</th>
						<th>Estado</th>						
					</tr>
				</thead>
				<tbody>
					<tr v-for="(i, k) of tabla.datos" :key="k" @click="irOrden(i.id)" class="c-hand">
						<td class="text-center">{{ i.id }}</td>
						<td>{{ i.fecha }}</td>
						<td>{{ i.cliente_nombre }}</td>
						<td>{{ i.tecnico_nombre }}</td>
						<td>{{ i.estado }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'SV_Ordenes',
		data() {
			return {
				form: {
					cargando: false,
					datos: {
						fecha: '',
						cliente: '',
						tecnico: '',
					}
				},
				clientes: [{ nombre: 'Cargando', id: 0 }],
				servicios: [{ nombre: 'Cargando', id: 0 }],
				tecnicos: [{ nombre: 'Cargando', id: 0 }],
				tabla: {
					cargando: false,
					datos: []
				}
			};
		},
		mounted() {
			document.title = 'Ordenes de Servicio';
			this.infoUsuario();
			this.listadoClientes();
			this.listadoTecnicos();
			this.listadoTabla();
		},
		methods: {
			infoUsuario() {
				if(Object.keys(this.$store.state.user).length === 0) {
					this.$store.state.api
						.get('usuario')
						.then(res => this.$store.commit('setUser', res.data))
						.catch(err => alert(err.response.data.mensaje));
				}
			},
			enviar() {
				this.form.cargando = true;
				this.$store.state.api
					.post('ordenes/crear', this.form.datos)
					.then(() => {
						this.form.datos = {
							fecha: '',
							cliente: '',
							tecnico: '',
						};
					})
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => {
						this.form.cargando = false;
						this.listadoTabla();
					});
			},
			listadoClientes() {
				this.$store.state.api
					.get('clientes/listado')
					.then(res => (this.clientes = res.data))
					.catch(err => alert(err.response.data.mensaje));
			},
			listadoTecnicos() {
				this.$store.state.api
					.get('usuario/listado_tecnicos')
					.then(res => (this.tecnicos = res.data))
					.catch(err => alert(err.response.data.mensaje));
			},
			listadoTabla() {
				this.tabla.cargando = true;
				this.$store.state.api
					.get('ordenes/sv_listado')
					.then(res => this.tabla.datos = res.data)
					.catch(err => alert(err.response.data.mensaje))
					.finally(() => this.tabla.cargando = false);
			},
			irOrden(id) {
				this.$router.push({
					name: 'SV_Orden',
					params:{ id: id }
				});
			}
		}
	};
</script>
